import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { useLoginCtx } from '../../contexts/login'

export const NonAuthenticatedRoute: React.FC<RouteProps> = observer(({ children, ...rest }) => {
	const loginCtx = useLoginCtx()

	return (
		loginCtx.checked
			? (
				<Route
					{...rest}
					render={({ location }) =>
						<>
							{
								loginCtx.loggedUser
									? <Redirect
										to={{
											pathname: `/`,
											state: { from: location }
										}}
									/>
									: children as any
							}
						</>
					}
				/>
			)
			: null
	)
})