import { useContext, createContext } from 'react'
import { action, observable } from 'mobx'

import { DoLogout } from '../controllers/RootUser/logout'
import { DoLogin } from '../controllers/RootUser/login'
import { GetLogged, Errors as GetLoggedErrors } from '../controllers/RootUser/getLogged'
import { RootUserType } from '../models/RootUser/rootUser'

const LoginCtx = observable({
	checked: false as boolean,
	loggedUser: undefined as RootUserType | undefined,
	doLogin: async (email: string, password: string) => {
		await DoLogin(email, password)
		await loadLoggedUser()
	},
	doLogout: async () => {
		await DoLogout()
		await loadLoggedUser()
	}
})

export const useLoginCtx = () => useContext(createContext(LoginCtx))

const loadLoggedUser = action(async () => {
	try {
		const { user } = await GetLogged() as any
		LoginCtx.loggedUser = user
	} catch ({ message: error }: any) {
		switch (error) {
			case GetLoggedErrors.NOT_AUTHORIZED:
				LoginCtx.loggedUser = undefined
				break
		}
	} finally {
		LoginCtx.checked = true
	}
})

loadLoggedUser()