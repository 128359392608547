import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { useLoginCtx } from '../../contexts/login'

export const AuthenticatedRoute: React.FC<RouteProps> = observer(({ children, ...rest }) => {
	const loginCtx = useLoginCtx()

	// trick to allow screen to re - rende
	useEffect(() => { }, [loginCtx.checked, loginCtx.loggedUser])

	return (
		loginCtx.checked
			? (
				<Route
					{...rest}
					render={({ location }) => (
						loginCtx.loggedUser
							? children as any
							: <Redirect
								to={{
									pathname: `/login`,
									state: { from: location }
								}}
							/>
					)}
				/>
			)
			: null
	)
})